<template>
  <supply-demand type="supply"></supply-demand>
</template>
<script>
import supplyDemand from "./index";
export default {
  components: {
    supplyDemand,
  },
};
</script>